@import "~rivals/styles/breakpoints";
@import "~rivals/styles/mixins";
@import "~rivals/styles/typography";
@import "~rivals/styles/variables";

.navInlineMenu.collapsed {
  display: none;
}

.navContainerHorizontalMenu {
  padding-top: $spacing-6;
  @include header-wrapper;
}

.navContainerHorizontalMenuLegacy {
  @include header-wrapper;
  @include sizes-starting-with($screen-tablet-max) {
    display: flex;
    height: 36px;
  }
}

.navContainerHorizontalMenu,
.navContainerHorizontalMenuLegacy {
  :global {
    .ant-menu-item-selected {
      /* stylelint-disable-next-line declaration-no-important */
      color: initial !important;
    }
  }
}

span.navItem,
a.navItem {
  @include font-primary-body-B5;
  color: $abyss;

  &.teamHeader {
    @include font-primary-bold-SB6;
  }

  &:hover {
    color: $abyss-70;
  }
}

.menuItemWrapper {
  @include sizes-starting-with($screen-sm-desktop-min) {
    align-items: center;
    display: flex;
    height: 100%;

    &.legacy {
      padding: 0 $spacing-16;
    }
  }
}

a.parentMenuLabel {
  // Disables dropdown label link navigation (default behavior),
  // to instead expand the dropdown and show its content.
  // Is applied to all menus with submenus.
  // Ex: Teams dropdown label is a link that redirects to teams page,
  // this disables the clickability of the link so the dropdown is instead expanded
  // and is only applied on touch screens.
  // See: components/app/Layout/Header/Nav/MenuBuilder/index.tsx
  @include sizes-ending-with($screen-tablet-max) {
    pointer-events: none;
  }
}
