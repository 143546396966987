@import "~rivals/styles/breakpoints";
@import "~rivals/styles/colors";
@import "~rivals/styles/mixins";
@import "~rivals/styles/variables";
@import "~rivals/styles/exports.module.scss";

.header {
  @include font-extended-headline-H5;
  background-color: $white;
  display: flex;
  @include sizes-starting-with($screen-tablet-max) {
    background-color: $white;
    flex-direction: column;
    height: 120px;
    width: 100%;
  }

  &.collapsed {
    @include sizes-ending-with($screen-tablet-max) {
      flex-direction: column;
    }
  }

  &.visible {
    @include sizes-ending-with($screen-tablet-max) {
      background: $white;
      bottom: 0;
      display: block;
      height: 100%;
      overflow-y: scroll;
      position: fixed;
      right: 0;
      top: 0;
      width: 100%;
      z-index: 10;
    }
  }

  &.hideSecondaryNav {
    height: fit-content;
  }

  .headerMenuContainer {
    background-color: $eye-black;
    border-bottom: $header-border-height solid $abyss;
    position: fixed;
    width: 100%;
    z-index: $header-z-index;

    .headerMenu {
      @include header-wrapper;
      display: flex;
      height: $header-height-desktop;
      justify-content: space-between;
      position: relative;

      .headerNavMobile {
        display: none;
        @include sizes-ending-with($screen-tablet-max) {
          align-items: center;
          display: flex;
          justify-content: center;

          .navMobileDropdown {
            background: url("/static/icons/icons_menumobile_white.svg")
              no-repeat center;
            background-size: 20px 15px;
            cursor: pointer;
            height: 100%;
            margin-left: -25px;
            width: 76px;
          }
        }
      }
    }
  }

  .headerLogoWrapper {
    display: flex;
  }

  .headerNavLargeBreakpoint {
    margin-top: $header-height-desktop + $header-border-height;
    z-index: 1;
  }

  .reducedSiteName {
    @include sizes-between(
      $screen-sm-desktop-min,
      $new-article-md-desktop-min - 1px
    ) {
      width: calc(100% - 536px);
    }
  }
}
